import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { NamedRedirect } from '../../components'; 

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  const state = useSelector(state => state);
  const {isAuthenticated} = state?.auth || {};

  const language = typeof window != 'undefined' && localStorage.getItem('language');
  const finnishLandingPage = typeof window != 'undefined' && window.location.pathname == '/';
  const englishLandingPage = typeof window != 'undefined' && window.location.pathname == '/en';
  if (typeof window != 'undefined' && finnishLandingPage && language == 'English') {
    return <NamedRedirect name="LandingPageEnglish" />;
  }
  if (typeof window != 'undefined' && englishLandingPage && language == 'Finnish') {
    return <NamedRedirect name="LandingPage" />;
  }
  return (
    <PageBuilder
      isAuthenticated={isAuthenticated}
      isLandingPage={true}
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
      options={{ isAuthenticated: isAuthenticated }}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
